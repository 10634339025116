import React, { useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";

const PopupNotice = ({ isOpen, onClose }) => {
  const [isChecked, setIsChecked] = useState(false); // Stato per la casella di controllo

  if (!isOpen) return null;

  const handleConfirm = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <div className="fixed mb-14 w-full inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-3/4 h-3/4 max-h-3/4 overflow-y-auto">
        <div className="flex relative justify-between items-start">
          <h2 className="md:text-2xl text-lg font-bold mb-4">
            Termini e Condizioni
          </h2>
          <div className="absolute right-4 top-0 sm:right-[60px] sm:top-[110px]">
            <IoMdCloseCircle
              className="block md:hidden"
              size={24}
              onClick={onClose}
            />
            <IoMdCloseCircle
              className="hidden md:block"
              size={35}
              onClick={onClose}
            />
          </div>
        </div>
        <p className="mb-4 overflow-y-auto">
          {/* Il testo dei termini e condizioni */}
          INFORMATIVA TRATTAMENTO DEI DATI PERSONALI (ART. 13 e 14 REG. UE
          2016/679) Ai fini previsti dal Regolamento UE n. 2016/679 relativo
          alla protezione delle persone fisiche con riguardo al trattamento dei
          dati personali, La informo che il trattamento dei dati personali da
          Lei forniti ed acquisiti dalla società HOTEL AL TARCENTINO DI MEMOLLA
          ARTI, via Dante n. 18 33017 Tarcento, part Iva 02462140308, mail
          info@altarcentino.com, saranno oggetto di trattamento nel rispetto
          della normativa prevista dal premesso Regolamento e dei diritti ed
          obblighi conseguenti. <br />
          <br />
          1. Chi è il TITOLARE DEL TRATTAMENTO Il titolare del trattamento è
          HOTEL AL TARCENTINO DI MEMOLLA ARTI, via Dante n. 18 33017 Tarcento,
          part Iva 02462140308, mail info@altarcentino.com. <br />
          <br /> 2. Perché raccogliamo i Suoi dati personali (Finalità del
          Trattamento) e quali dati raccogliamo? La nostra azienda HOTEL AL
          TARCENTINO DI MEMOLLA ARTI raccoglie i seguenti dati personali per
          gestire i rapporti contrattuali con la clientela (attività di Hotel e
          Ristorante), nonché i dati dei fornitori per i relativi obblighi di
          legge: nome, cognome, residenza, codice fiscale e, solo per il
          servizio di alloggio, copia del documento di identità; data e luogo di
          nascita, numero di telefono, indirizzo e-mail. Previo consenso
          espresso dell’interessato, i sopra citati dati personali ed i dati
          ulteriori come appresso descritti, sono, inoltre, raccolti,
          archiviati, utilizzati e trattati da HOTEL AL TARCENTINO DI MEMOLLA
          ARTI: c) per fornire servizi di vendita (a titolo esemplificativo e
          non esaustivo: servizi di assistenza gratuita, servizi di cortesia),
          per l’invio (anche tramite posta ordinaria, posta elettronica, sms e
          mms, social network, instant messaging) di comunicazioni informative e
          promozionali, newsletter sui nostri prodotti, sulle nostre iniziative,
          vendite esclusive e su eventi e simili iniziative organizzati da HOTEL
          AL TARCENTINO DI MEMOLLA ARTI, compresi eventuali inviti a tali
          eventi, nonché infine per l’invio di questionari per la rilevazione
          del grado di soddisfazione dei servizi offerti (Direct marketing). In
          relazione alle finalità di cui al punto c) che precede la ditta HOTEL
          AL TARCENTINO DI MEMOLLA ARTI potrà raccogliere, archiviare,
          utilizzare e trattare dati ulteriori rispetto a quelli necessari per
          la gestione del rapporto contrattuale ed adempiere ad obblighi di
          legge, come di seguito indicati a titolo meramente esemplificativo: •
          dati raccolti durante le visite nell'Albergo e nel Ristorante, ovvero
          in occasione della partecipazione ad eventi, ovvero attraverso
          procedure di raccolta dati anche on-line, mediante compilazione del
          form all'indirizzo https://www.altarcentino.com/prenota-una-stanza,
          iter di registrazione, creazione dell’area riservata, o di acquisti
          effettuati online o altre informazioni inviate volontariamente
          attraverso un modulo sul sito o che Lei abbia condiviso contattandoci:
          data di nascita, fascia di età, date di eventi familiari, professione,
          registrazione dei singoli acquisti, telefono, indirizzo e-mail,
          fotografia, audio e filmati, sesso, dettaglio dei prodotti acquistati,
          prezzo, sconto, risposta a contatti, eventi a cui il cliente ha
          partecipato. Il conferimento dei dati di cui al punto a) è necessario
          nel caso il pagamento sia effettuato a fronte della emissione della
          fattura, anzicchè dello scontrino fiscale o di altro documento
          fiscale. Il conferimento dei dati di cui alla lettera b) e c) è libero
          e facoltativo e l’utilizzo degli stessi è condizionato al consenso,
          pertanto l’eventuale rifiuto al conferimento di tali dati non
          consentirà alla HOTEL AL TARCENTINO DI MEMOLLA ARTI di perseguire le
          finalità ivi indicate. Categorie particolari di dati: non Le
          chiederemo di fornire e non tratteremo i Suoi dati personali sensibili
          (ad esempio, sulla razza, sull’etnia, su opinioni politiche, credenze
          religiose o filosofiche, ecc.) eccetto quando il trattamento delle
          informazioni sia richiesto o consentito dalla legge applicabile o
          abbiamo ottenuto il Suo libero ed esplicito consenso, manifestato in
          forma scritta. <br />
          <br /> 3. Condizioni applicabili al consenso dei minori Il trattamento
          di dati personali di minori è lecito ove il minore abbia almeno 18
          anni. Ove il minore abbia un’età inferiore ai 18 anni, tale
          trattamento è lecito soltanto se e nella misura in cui tale consenso è
          prestato o autorizzato dal titolare della responsabilità genitoriale.{" "}
          <br />
          <br />
          4. Su quale base giuridica trattiamo i Suoi dati? Il trattamento dei
          Suoi dati personali si basa su uno o più dei seguenti fondamenti
          giuridici: - Consenso: chiediamo il Suo consenso per utilizzare i Suoi
          dati per pubblicità, promozioni, attività di marketing; - Prestazioni
          contrattuali: la Sua richiesta di acquisto e di pagamento a fronte
          dell’emissione di documento fiscale contenente i Suoi dati personali,
          renderà necessario trattare i dati forniti di cui al punto a); -
          Obblighi di legge: quando strettamente necessario, tratteremo i Suoi
          dati personali per adempire ad un obbligo legale; - Interessi
          legittimi: nei limiti in cui il nostro utilizzo non leda i Suoi
          diritti, raccoglieremo e tratteremo i Suoi dati personali ove avessimo
          un interesse legittimo utile per le finalità di tutela giudiziaria e
          di protezione dei nostri servizi. <br />
          <br /> 5. Quali sono le modalità di trattamento dei Suoi dati
          personali? Il trattamento è realizzato attraverso operazioni,
          effettuate con o senza l’ausilio di strumenti elettronici e consiste
          nella raccolta, registrazione, organizzazione, conservazione,
          consultazione, elaborazione, modificazione, selezione, estrazione,
          raffronto, utilizzo, interconnessione, blocco, comunicazione,
          cancellazione e distruzione dei dati. La ditta HOTEL AL TARCENTINO DI
          MEMOLLA ARTI non adotta alcun processo decisionale automatizzato,
          compresa la profilazione. <br />
          <br /> 6. Quanto tempo conserviamo i dati (conservazione dei dati)? La
          conservazione dei dati personali avverrà in forma cartacea e/o
          elettronica/informatica e per il tempo strettamente necessario al
          soddisfacimento delle finalità di cui sopra, nel rispetto delle
          normative vigenti. Per finalità di marketing diretto conserviamo i
          tuoi dati per un massimo di 24 mesi dal momento del conferimento,
          fermo restando il Suo diritto di richiedere la cessazione del
          trattamento in qualsiasi momento. <br />
          <br /> 7. Come trattiamo i Suoi dati? I dati personali sono trattati
          solo da persone autorizzate, che operano sotto la responsabilità e la
          supervisione di HOTEL AL TARCENTINO DI MEMOLLA ARTI. Tali persone sono
          adeguatamente formate e si impegnano al rispetto della normativa sulla
          protezione dei dati personali. I dati personali potrebbero essere
          trattati anche da terzi che svolgono attività in outsourcing per
          nostro conto, come i nostri fornitori di servizi (compresi i fornitori
          di servizi di marketing, aziende di elaborazione dati, provider
          informatici). Questi terzi sono obbligati a proteggere la riservatezza
          dei dati e ad utilizzarli solo in conformità con le istruzioni da noi
          fornite. <br />
          <br /> 8. Quali sono i Suoi diritti? In qualità di interessato Lei ha
          il diritto di: - accedere ai dati personali che La riguardano; -
          richiedere la rettifica dei dati personali inesatti o incompleti; -
          richiedere la cancellazione dei dati personali che non sono più
          necessari per le finalità per cui sono stati raccolti; - richiedere la
          limitazione del trattamento dei dati personali nei casi previsti dalla
          legge; - ricevere in un formato strutturato, di uso comune e leggibile
          da dispositivo automatico i dati personali che La riguardano e
          trasmettere tali dati a un altro titolare del trattamento nei casi
          previsti dalla legge; - opporsi al trattamento dei dati personali nei
          casi previsti dalla legge. Per esercitare tali diritti, La preghiamo
          di contattarci utilizzando i dati di contatto forniti al punto 1.{" "}
          <br />
          <br /> 9. Diritto di reclamo all’autorità di controllo Per il caso di
          presunti illeciti o violazioni del Regolamento UE 2016/679, Lei ha il
          diritto di presentare un reclamo all’Autorità Garante per la
          Protezione dei Dati Personali, con sede in Piazza di Montecitorio, 121
          - 00186 Roma, www.garanteprivacy.it. <br />
          <br /> 10. Aggiornamenti della presente informativa La presente
          informativa può essere aggiornata periodicamente per riflettere
          modifiche alle leggi sulla privacy o per adattarsi alle modifiche dei
          nostri servizi. Le eventuali modifiche verranno pubblicate sul nostro
          sito web e saranno in vigore dalla data della loro pubblicazione.{" "}
        </p>
        <form onSubmit={handleConfirm} className="flex flex-col items-center">
          <div className="flex items-center mb-4">
            <input
              type="checkbox"
              id="acceptTerms"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              className="mr-2"
            />
            <label htmlFor="acceptTerms">
              Accetto i{" "}
              <a href="/terms" className="text-blue-600">
                Termini e Condizioni
              </a>
            </label>
          </div>
          <button
            type="submit"
            disabled={!isChecked}
            className={`bg-blue-500 text-white px-4 py-2 rounded ${
              !isChecked ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            Conferma
          </button>
        </form>
      </div>
    </div>
  );
};

export default PopupNotice;
