import React from "react";
import { Link, useLocation } from "react-router-dom";
import { BiFoodMenu } from "react-icons/bi";
import { RiInformation2Line } from "react-icons/ri";

const Footer = ({ id }) => {
  const location = useLocation();
  const currentView = location.state?.view || "menu"; // Default to "menu" if undefined

  const getColor = (view) => (currentView === view ? "#2978A3" : "#000000");

  return (
    <footer className="fixed h-[60px] flex justify-center bottom-0 left-0 right-0 p-4 bg-gray-100">
      <div className="flex w-[75%] justify-between">
        <div className="flex-1 flex justify-center flex-col items-center">
          <Link to={`/${id}`} state={{ view: "menu" }}>
            <p className="flex justify-center">
              <BiFoodMenu color={getColor("menu")} size={22} />
            </p>
            <h4
              className="text-md font-inter mb-1"
              style={{ color: getColor("menu") }}
            >
              Menu
            </h4>
          </Link>
        </div>
        <div className="flex-1 flex justify-center flex-col items-center">
          <Link to={`/${id}`} state={{ view: "info" }}>
            <p className="flex justify-center">
              <RiInformation2Line color={getColor("info")} size={22} />
            </p>
            <h4
              className="text-md font-inter mb-1"
              style={{ color: getColor("info") }}
            >
              Info
            </h4>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
