import React, { useCallback, useEffect, useState } from "react";
import { database } from "../utils/firebase";
import { useParams } from "react-router-dom";
import { get, ref as dbRef } from "firebase/database";
import { BsArrowUpRightSquare } from "react-icons/bs";
import { TbLockSquareRounded } from "react-icons/tb";
import { IoWarningOutline } from "react-icons/io5";
import axios from "axios";
import PopupNotice from "../utils/popupNotice";
import PopupExercise from "../utils/popupExercise";
import Footer from "../utils/footer";

// Helper function to get the current day key
const getCurrentDayKey = () => {
  const days = [
    "001-Monday",
    "002-Tuesday",
    "003-Wednesday",
    "004-Thursday",
    "005-Friday",
    "006-Saturday",
    "007-Sunday",
  ];
  const today = new Date().getDay(); // 0 is Sunday, 1 is Monday, etc.
  return days[(today + 6) % 7]; // Adjust so Monday is first
};

// Helper function to check if the current time is within the given hours
const isOpenNow = (hours) => {
  const now = new Date();
  const currentTime = `${now.getHours()}:${now.getMinutes()}`;

  // Convert time range to minutes
  const timeToMinutes = (time) => {
    const [hour, minute] = time.split(":").map(Number);
    return hour * 60 + minute;
  };

  // Split hours into periods and check if current time falls within any period
  return hours.split("|").some((period) => {
    const [start, end] = period.trim().split(" - ");
    return (
      timeToMinutes(currentTime) >= timeToMinutes(start) &&
      timeToMinutes(currentTime) <= timeToMinutes(end)
    );
  });
};

const Info = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState("");
  const [mapUrl, setMapUrl] = useState("");
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isPopupOpenNotes, setPopupOpenNotes] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleExercisePopup = () => {
    setPopupOpen(true);
  };

  const handleNotesPopup = () => {
    setPopupOpenNotes(true);
  };

  const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API;

  const fetchAddress = useCallback(
    async (url) => {
      // Extract latitude and longitude from the URL
      const latRegex = /!3d([0-9.-]+)/;
      const lngRegex = /!4d([0-9.-]+)/;

      const latMatch = url.match(latRegex);
      const lngMatch = url.match(lngRegex);

      const latitude = latMatch ? latMatch[1] : null;
      const longitude = lngMatch ? lngMatch[1] : null;

      if (latitude && longitude) {
        try {
          const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${API_KEY}`
          );

          if (response.data.results && response.data.results.length > 0) {
            const formattedAddress = response.data.results[0].formatted_address;
            setAddress(formattedAddress);
            setMapUrl(
              `https://www.google.com/maps/embed/v1/place?key=${API_KEY}&q=${encodeURIComponent(
                formattedAddress
              )}`
            );
          } else {
            setAddress("Address not found");
            setMapUrl("");
          }
        } catch (error) {
          setAddress("Error fetching address");
          setMapUrl("");
        }
      } else {
        setAddress("Invalid URL");
        setMapUrl("");
      }
    },
    [API_KEY]
  );

  useEffect(() => {
    if (id) {
      const dataRef = dbRef(database, `${id}`);
      get(dataRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const dataVal = snapshot.val();
            const items = dataVal.Info || {};
            setData(items);

            // Fetch address if urlGMaps is present
            if (items.urlGMaps) {
              fetchAddress(items.urlGMaps);
            }
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, fetchAddress]);

  // Extract business hours from data
  const businessHours = {
    "001-Monday": data?.["001-Monday"] || "Not available",
    "002-Tuesday": data?.["002-Tuesday"] || "Not available",
    "003-Wednesday": data?.["003-Wednesday"] || "Not available",
    "004-Thursday": data?.["004-Thursday"] || "Not available",
    "005-Friday": data?.["005-Friday"] || "Not available",
    "006-Saturday": data?.["006-Saturday"] || "Not available",
    "007-Sunday": data?.["007-Sunday"] || "Not available",
  };

  // Get current day key and hours
  const currentDayKey = getCurrentDayKey();
  const currentDayHours = businessHours[currentDayKey];
  const isCurrentDayOpen = isOpenNow(currentDayHours);

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div className="info fixed flex justify-start top-0 left-0 right-0 w-full  py-3 px-2 max-w-screen-lg mx-auto text-3xl font-bold mb-10 z-50">
        <h1 className=" font-inter">{data?.nome}</h1>
      </div>

      <div className="max-w-screen-lg mx-auto w-[95%] mb-20 mt-10">
        <img
          src={data?.logoUrl}
          alt={data?.nome}
          className="w-full rounded-lg  mb-4"
        />

        <div className="relative inline-block text-left w-full md:w-full">
          <div className="flex items-center">
            <button
              onClick={toggleDropdown}
              className="inline-flex font-inter justify-between items-center w-full rounded-t-md px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
            >
              <p
                className={`text-xl font-inter mr-4 ${
                  isCurrentDayOpen ? "text-green-500" : "text-red-500"
                }`}
              >
                {isCurrentDayOpen ? "Open" : "Closed"}
              </p>
              {currentDayKey.split("-")[1]}: {currentDayHours}
            </button>
          </div>
          {isOpen && (
            <div
              className="origin-top-right left-0 w-full md:w-full bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              style={{
                maxHeight: "50vh",
                overflowY: "auto",
              }}
            >
              <div className="py-1">
                {Object.entries(businessHours).map(([key, hours]) => {
                  const isActive = key === currentDayKey;
                  return (
                    <div
                      key={key}
                      className={`px-4 font-inter flex justify-between items-center py-2 text-sm ${
                        isActive
                          ? "bg-gray-100 text-gray-900 font-semibold"
                          : "text-gray-700 hover:bg-gray-100"
                      }`}
                    >
                      <p>{key.split("-")[1]}</p> <p>{hours}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        <div className="mb-3 bg-white p-4 -mt-[6px] rounded-b-md">
          {mapUrl ? (
            <iframe
              title="Google Map"
              src={mapUrl}
              width="100%"
              height="200"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              className="rounded-lg "
            ></iframe>
          ) : (
            <p>No map available</p>
          )}
          <p className="mt-2 py-2  font-inter text-lg">{address}</p>
          <hr className="pt-1" />
          <p className="pt-2 text-xl  font-inter">
            Tel: <a href={`tel:${data?.telefono}`}>{data?.telefono}</a>
          </p>
        </div>
        <div className="text-lg">
          <p className="text-sm  font-inter text-gray-500 mt-2">
            Data updated on {data?.version || "24 aprile 2024"}
          </p>
        </div>
        <div className="mb-4 bg-white px-5 py-3 rounded-md mt-2">
          <div className="flex gap-3 items-center">
            <BsArrowUpRightSquare color="#1A73E8" size={18} />
            <p
              onClick={handleExercisePopup}
              className="pt-2  font-inter py-2 text-xl cursor-pointer"
            >
              Aggiungi il Tuo Esercizio
            </p>
            <PopupExercise
              isOpen={isPopupOpen}
              onClose={() => setPopupOpen(false)}
            />
          </div>

          <hr className="pt-1" />
          <div className="flex gap-3 items-center">
            <IoWarningOutline color="#F4B400" size={22} />
            <p className="pt-2  font-inter py-2 text-xl">
              <a href="mailto:business@urestaurants.app">Segnala un Problema</a>
            </p>
          </div>
          <hr className="pt-1" />
          <div className="flex gap-3 items-center">
            <TbLockSquareRounded color="#B0BEC5" size={22} />
            <p
              onClick={handleNotesPopup}
              className="pt-2  font-inter py-2 text-xl cursor-pointer"
            >
              Note Legali
            </p>
            <PopupNotice
              isOpen={isPopupOpenNotes}
              onClose={() => setPopupOpenNotes(false)}
            />
          </div>
        </div>
      </div>
      <Footer id={id} />
    </>
  );
};

export default Info;
